import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
// import AWS from "aws-sdk";
import { Buffer } from "buffer";

import { toast } from "react-toastify";
import { getTeams } from "../../../../utils/baseAPI";
import CONFIG from "../../../../utils/config";
import JobApplyForm from "./JobApplyForm";
import JobContent from "./JobContent";
import { Details } from "../../../../constant/Details";


export default function JobDescription() {
  const [listdata, setListdata] = useState([]);
  const [fileUrl, setFileurl] = useState(false);
  const [fileName, setFileName] = useState(false);
  const [joblink, setJobLink] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [inputValue, setInputValue] = useState([
    {
      fullName: "",
      email: "",
      phoneNumber: "",
    },
  ]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      !/[0-9]/.test(String.fromCharCode(charCode)) ||
      inputValue?.phoneNumber?.length >= 10
    ) {
      e.preventDefault();
    }
  };

  const handleFileInput = (e) => {
    const fileInput = document.getElementById("customFile");
    const res_field = fileInput.value;
    const extension = res_field
      .substr(res_field.lastIndexOf(".") + 1)
      .toLowerCase();
    const allowedExtensions = ["pdf","doc"];

    if (res_field.length > 0) {
      if (allowedExtensions.indexOf(extension) !== -1) {
        const data = e.target.files[0];

        const timestamp = new Date().getTime();
        const fileName = timestamp + "_" + data.name;

        const formData = new FormData();

        formData.append("file", data);
        formData.append("name", fileName);

        axios
          .post(CONFIG?.APIURL + `user/file/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            setFileurl(response.data.data);
            setFileName(fileName);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        fileInput.value = "";
        swal(Details?.FILE_SELECTION_ERROR);
      }
    }
  };


  const fetchData = async () => {
    setIsLoading(true);
    let params = window.location.pathname.split("/");
    let replacestring = params[2].replaceAll("-", " ");
    const splitarr = replacestring.split(" ");

    for (var i = 0; i < splitarr.length; i++) {
      splitarr[i] = splitarr[i].charAt(0).toUpperCase() + splitarr[i].slice(1);
    }

    const jobname = splitarr.join(" ");

    let teams = await getTeams(`job/${jobname}`);
    if (teams) {
      setListdata(teams);
      setJobLink(`https://3elitist.com/job/${jobname}`);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const aplyjob = (e) => {
    e.preventDefault();
    var epattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var eresult = epattern.test(inputValue?.email);
    if (!inputValue?.fullName) {
      toast.dismiss();
      toast.error("Enter Full Name");
      return false;
    }
    if (!inputValue?.email) {
      toast.dismiss();
      toast.error("Enter Email");
      return false;
    }
    if (!eresult) {
      toast.dismiss();
      toast.error("Enter Valid Email");
      return false;
    }
    if (!inputValue?.phoneNumber) {
      toast.dismiss();
      toast.error("Enter Phone Number");
      return false;
    }
    if (inputValue?.phoneNumber?.length !== 10) {
      toast.dismiss();
      toast.error("Phone Number must be 10 digit");
      return false;
    }
    if (!fileUrl) {
      toast.dismiss();
      toast.error("Select Document");
      return false;
    }
    let data = {
      name: inputValue?.fullName,
      email: inputValue?.email,
      phone: inputValue?.phoneNumber,
      resume: fileUrl,
      fileName: fileName,
      job: listdata?._id,
    };
    jobAPI(data);
  };

  const jobAPI = (data) => {
    if (data) {
      setIsLoading(true);
      if (data) {
        axios
          .post(CONFIG?.APIURL + "api/v1/applyjob", data)
          .then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              swal(Details?.JOB_POST_RESPONSE);
              setInputValue("");
              document.getElementById("customFile").value = "";
            }
          })
          .catch((err) => {
            setIsLoading(false);
            swal(Details?.CONTACT_ERROR);
          });
      }
    }
  };

  return (
    <div className="vc_column_container" style={{ padding: "5% 0 8%" }}>
      <div className="mkdf-row-grid-section p-2 shadow">
        <div className="col-md-12">
          <div className="d-lg-flex">
            <JobContent listdata={listdata} joblink={joblink} />

            <JobApplyForm
              inputValue={inputValue}
              handleInputChange={handleInputChange}
              handleKeyPress={handleKeyPress}
              handleFileInput={handleFileInput}
              aplyjob={aplyjob}
              isLoading={isLoading}
              listdata={listdata}
              joblink={joblink}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
