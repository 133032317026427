import React from "react";
import { Style } from "../../../common/Style";

export default function Portfolio() {
  const portfolio = [
    {
      title: "QuirkPlus",
      link: "https://quirkplus.com/",
      image: "/assets/images/portfolio/quirkplus.jpeg",
      alt: "quirkplus-img",
      description: "Social Media",
    },
    {
      title: "Air Market",
      link: " https://airmarket.io/t",
      image: "/assets/images/portfolio/AirMarket1.png",
      alt: "air-market-img",
      description: "Tourism",
    },
    {
      title: "Steev",
      link: "https://steevlive.com/",
      image: "/assets/images/portfolio/Steev1.png",
      alt: "steev-img",
      description: "Social Media",
    },
  ];

  return (
    <>
      <div className="vc_row wpb_row vc_row-fluid vc_custom_1528805580898">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="wpb_text_column wpb_content_element">
                <div className="wpb_wrapper">
                  <h3
                    className="text-center"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Our Portfolio
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid vc_custom_1529401716611">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="mkdf-elements-holder mkdf-three-columns mkdf-responsive-mode-1024">
                    {portfolio?.map((item, key) => {
                      return (
                        <div
                          className="mkdf-eh-item"
                          data-item-class="mkdf-eh-custom-6130"
                          data-1367-1600="50px 22px 0 0"
                          data-1025-1366="40px 22px 0 0"
                          data-769-1024="0 0"
                          data-681-768="0 0"
                          data-680="0 0"
                          key={key}
                        >
                          <div className="mkdf-eh-item-inner">
                            <div
                              className="mkdf-eh-item-content mkdf-eh-custom-6130"
                              style={{ padding: "0 22px 0 0" }}
                            >
                              <div className="mkdf-single-image-holder mkdf-rounded-image">
                                <div className="mkdf-si-inner bg-white">
                                  <img
                                    width="345"
                                    height="631"
                                    src={item?.image}
                                    className="attachment-full size-full"
                                    alt={item?.alt}
                                    title={item?.alt}
                                    decoding="async"
                                    loading="lazy"
                                    style={Style.portfolioImageStyle}
                                    data-aos="zoom-in"
                                    data-aos-duration="1000"
                                  />
                                </div>
                              </div>

                              <div className="mkdf-section-title-holder mkdf-st-standard mkdf-st-title-left mkdf-st-normal-space">
                                <div className="mkdf-st-inner">
                                  <h4 className="mkdf-st-title">
                                    {item?.title}
                                  </h4>
                                  <p className="mkdf-st-text mt-0">
                                    {item?.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="d-flex justify-content-center pt-3 pb-3">
                    <a
                      href="/portfolio"
                      className="text-white p-2"
                      style={Style.portfolioButton}
                    >
                      View All Portfolio
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
