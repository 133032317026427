import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Details } from "../../../constant/Details";
import BookConsultation from "./BookConsultation";

function MainSlider() {
  const [show, setShow] = useState(false);

  const carouselData = [
    {
      image: "/assets/images/home/ideas-reality.jpg",
      alt: "slider2-img",
      title: "We Turn Your Ideas Into Reality",
      description: `we're dream-weavers, turning your vision into functional, beautiful, and user-friendly solutions. When you choose ${Details?.COMPANY_NAME}, you're choosing a partner committed to nurturing your ideas into reality.`,
    },
    {
      image: "/assets/images/home/digital-agency-slider.jpg",
      alt: "slider3-img",
      title: "We Are A Solution Driven Team",
      description:
        "we're driven to find the right solutions. Our commitment to innovation, expertise, and a results-oriented mindset sets us apart. When you choose us, you're choosing a partner that thrives on turning challenges into opportunities. Let us be the driving force behind your success.",
    },
    {
      image: "/assets/images/home/business-needs.jpg",
      alt: "slider1-img",
      title: "We Can Solve Your Business Needs",
      description:
        "With a dedicated team of problem solvers, we'll assess your unique needs and deliver tailored solutions that drive success. Let's work together to overcome challenges, seize opportunities, and pave the way for your success.",
    },
  ];

  return (
    <>
      <style jsx="true">{`
        .carousel-caption {
          position: absolute;
          margin: 0;
          top: 38%;
          left: 36%;
          transform: translate(-50%, -50%);
        }
        .carousel-control-next-icon,
        .carousel-control-prev-icon {
          background-color: #05054a !important;
          border-radius: 5px;
        }
      `}</style>

      <div className="vc_row wpb_row vc_row-fluid slider-shadow vc_custom_1574074545429">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="wpb_revslider_element wpb_content_element">
                <div
                  id="carouselExampleCaptions"
                  className="carousel slide carousel-fade"
                  data-bs-ride="carousel"
                  data-bs-interval="1000000"
                >
                  <div className="carousel-inner">
                    {carouselData?.map((item, key) => {
                      return (
                        <div
                          key={key}
                          className={`bg-body-tertiary carousel-item carousel-item ${
                            key === 0 ? "active" : ""
                          }`}
                        >
                          <img
                            src={item?.image}
                            className="d-block w-100"
                            alt={item?.alt}
                            title={item?.alt}
                            width="1320"
                            height="583"
                            decoding="async"
                            loading="lazy"
                            style={{ width: "1349px", height: "464px" }}
                          />
                          <div className="carousel-caption text-start">
                            <h1
                              className="text-white main-slider-title"
                              data-aos="fade-up"
                              data-aos-duration="2000"
                            >
                              {item?.title}
                            </h1>
                            <p
                              className="fs-5 d-xl-block d-none"
                              data-aos="fade-up"
                              data-aos-duration="2000"
                            >
                              {item?.description}
                            </p>

                            <div className="mt-5 mb-5">
                              <Link
                                to="/our-services"
                                itemProp="url"
                                style={{ padding: "12px 40px" }}
                                className="mkdf-btn mkdf-btn-large mkdf-btn-solid bg-info me-3"
                              >
                                <span className="mkdf-btn-text">
                                  {Details?.READ_MORE}
                                </span>
                              </Link>

                              <Button
                                className="btn mkdf-btn mkdf-btn-large mkdf-btn-solid bg-primary"
                                onClick={() => setShow(true)}
                              >
                                {Details?.BOOK_CONSULTATION}
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookConsultation show={show} setShow={setShow} />
    </>
  );
}

export default MainSlider;
